import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Loading from "../Loading";
import Title from "../Title";
import Home from "../Home";
import Distance from "../Distance";
import Size from "../Size";
import backgroundAudio from "../sound/Space_bgrd.mp3";

function RoutesAnimation() {
  const history = useNavigate();
  const location = useLocation();

  function playAudio() {
    const audio = document.getElementById("autoplay");
    audio.volume = 0.3;
    audio.play();
  }

  return (
    <AnimatePresence>
      <audio id="autoplay" loop>
        <source src={backgroundAudio} type="audio/mp3" />
      </audio>
      <Routes location={location} key={location.key}>
        <Route path="/" element={<Loading history={history} />} />
        <Route
          path="/Title"
          element={<Title history={history} playAudio={playAudio} />}
        />
        <Route
          path="/Home"
          element={<Home history={history} playAudio={playAudio} />}
        />
        <Route
          path="/Distance"
          element={<Distance history={history} playAudio={playAudio} />}
        />
        <Route
          path="/Size"
          element={<Size history={history} playAudio={playAudio} />}
        />
      </Routes>
    </AnimatePresence>
  );
}

export default RoutesAnimation;
