import React, { useEffect } from "react";
import { motion } from "framer-motion";
import GridLoader from "react-spinners/GridLoader";

function Loading({ history }) {
  // const history = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      history("/Title");
    }, 3000);
  }, []);

  return (
    <motion.div
      className="main-div"
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.7 }}
      transition={{ duration: 2, ease: "easeInOut" }}
      exit={{ opacity: 0 }}
    >
      <GridLoader color="white" style={{ gridRowStart: 3 }} />
    </motion.div>
  );
}

export default Loading;
