import React, { useEffect, useRef } from "react";
import { Animated } from "react-native";
import TitleImage from "./Img/Title.png";
import { ClickAnimation } from "./Components/ClickAnimation";
import { motion } from "framer-motion";

const duration = 1000;

export default function Title({ history, playAudio }) {
  const fadeAnimation = new Animated.Value(0);
  const containerVariants = {
    hidden: {
      y: 0,
    },
    visible: {
      // opacity: 1,
      transition: { delay: 2, duration: 2 },
    },
    exit: {
      y: "100vh",
      transition: { ease: "easeInOut", duration: 2 },
    },
  };

  function handleClick() {
    playAudio();
    history("/Home");
  }

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(fadeAnimation, {
          toValue: 0.5,
          duration: duration,
          useNativeDriver: true,
        }),
        Animated.timing(fadeAnimation, {
          toValue: 0,
          duration: duration,
          useNativeDriver: true,
        }),
      ])
    ).start();
  }, []);

  const click = useRef();
  ClickAnimation(click, { duration: 1000 });
  return (
    <motion.div
      key="Title"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="effect-container" ref={click} onClick={handleClick}>
        <div
          className="main-div"
          style={{ gridTemplateRows: "3fr repeat(3, 1fr) 3fr" }}
        >
          <motion.img
            className="title"
            src={TitleImage}
            alt="Solar System is Pretty Empty"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 3, duration: 2 }}
            // exit={{ opacity: 0 }}
          />
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.7 }}
            transition={{ delay: 3.5, duration: 2 }}
            // exit={{ opacity: 0 }}
            className="sub-title"
          >
            A True Scale of the Solar System
          </motion.h2>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{ delay: 3.5, duration: 2 }}
            style={{ gridRowStart: 4 }}
            // exit={{ opacity: 0 }}
          >
            <Animated.Text
              style={{
                opacity: fadeAnimation,
                fontSize: "20px",
                fontWeight: "light",
                color: "white",
                paddingTop: "1rem",
              }}
            >
              Click to Start
            </Animated.Text>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}
