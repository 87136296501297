import React from "react";

function Circle({ height }) {
  return (
    <svg style={{ height: (height || 18276 / 50), width: 50 }}>
      <circle
        cx="25"
        cy="25"
        r="3"
        fill="#606d7f"
        stroke="#606d7f"
        strokeWidth="1"
      />
    </svg>
  );
}

export default Circle;
