import Sun from "../Img/Sun-2.svg";
import Mercury from "../Img/Planet_Mercury.svg";
import Venus from "../Img/Planet_Venus.svg";
import Earth from "../Img/Planet_Earth.svg";
import Mars from "../Img/Planet_Mars.svg";
import Jupiter from "../Img/Planet_Jupiter.svg";
import Saturn from "../Img/Planet_Saturn.svg";
import Uranus from "../Img/Planet_Uranus.svg";
import Neptune from "../Img/Planet_Neptune.svg";

export const PlanetImages = [
  Sun,
  Mercury,
  Venus,
  Earth,
  Mars,
  Jupiter,
  Saturn,
  Uranus,
  Neptune,
];
