import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import Typed from "typed.js";
import { ClickAnimation } from "./Components/ClickAnimation";
import RArrow from "./Img/Arrow-Right.svg";
import LArrow from "./Img/Arrow-Left.svg";
import { PlanetImages } from "./Components/PlanetImages";

function Home({ history, playAudio }) {
  const [check, setCheck] = useState(true);
  const [textShow, setText] = useState(true);
  const [index, setIndex] = useState(0);
  function backClick() {
    history("/Title");
  }

  const info = {
    name: [
      "SUN",
      "MERCURY",
      "VENUS",
      "EARTH",
      "MARS",
      "JUPITER",
      "SATURN",
      "URANUS",
      "NEPTUNE",
    ],
    text: [
      [
        "This is the Sun.",
        "A Yellow Dwarf.",
        "The only star in the Solar Sytem.",
        "4.603 billion years old.",
        "SUN",
      ],
      [
        "This is Mercury.",
        "The smallest planet in the Solar Sytem.",
        "88 days in a year.",
        "167°C average temperature.",
        "MERCURY",
      ],
      [
        "This is Venus.",
        "The hottest planet in the Solar Sytem.",
        "224.7 days in a year.",
        "464°C average temperature.",
        "VENUS",
      ],
      [
        "This is Earth.",
        "The place we call home.",
        "365 days in a year.",
        "15°C average temperature.",
        "EARTH",
      ],
      [
        "This is Mars.",
        "The red planet in the Solar Sytem.",
        "687 days in a year.",
        "-65°C average temperature.",
        "MARS",
      ],
      [
        "This is Jupiter.",
        "The biggest planet in the Solar Sytem.",
        "4331 days in a year.",
        "-110°C average temperature.",
        "JUPITER",
      ],
      [
        "This is Saturn.",
        "The planet with 82 moons.",
        "10,747 days in a year.",
        "-140°C average temperature.",
        "SATURN",
      ],
      [
        "This is Uranus.",
        "The planet that rotates on its side.",
        "30,589 days in a year.",
        "-195°C average temperature.",
        "URANUS",
      ],
      [
        "This is Neptune.",
        "The farthest planet in the Solar System.",
        "59,800 days in a year.",
        "-200°C average temperature.",
        "NEPTUNE",
      ],
    ],
    next: [
      "MERCURY",
      "VENUS",
      "EARTH",
      "MARS",
      "JUPITER",
      "SATURN",
      "URANUS",
      "NEPTUNE",
      "SUN",
    ],
    color: [
      "#ffe757",
      "white",
      "white",
      "#77d1f6",
      "white",
      "white",
      "white",
      "white",
      "white",
    ],
    image: PlanetImages,
  };

  const containerVariants = {
    hidden: {
      y: "-100vh",
    },
    visible: {
      // opacity: 1,
      y: 0,
      transition: { delay: 2, duration: 2 },
    },
    exit: {
      opacity: 0,
      // y: "100vh",
      transition: { ease: "easeInOut", duration: 2 },
    },
  };

  const el = useRef(null);
  const typed = useRef(null);

  var options = {
    strings: info.text[index],
    typeSpeed: 50,
    backSpeed: 50,
    // startDelay: 5000,
    backDelay: 2000,
    showCursor: false,
    onComplete: (self) => {
      setText(false);
      setCheck(false);
      typed.current.destroy();
    },
  };

  const click = useRef();
  ClickAnimation(click, { duration: 1000 });

  function resetType() {
    // options.strings = ["Sun"];
    if (textShow) {
      typed.current.destroy();
      console.log(typed.current.options);
      setCheck(false);
      setText(false);
    } else {
      // setCheck(true);
      setText(true);
      typed.current.options.strings = options.strings;
      typed.current.options.startDelay = 0;
      console.log(typed.current.options);
      typed.current.reset();
    }
  }

  function nextIndex() {
    setText(true);
    setCheck(false);
    let temp = index;
    if (temp === 8) {
      temp = 0;
    } else {
      temp += 1;
    }
    setIndex(temp);
    options.strings = info.text[temp];
    typed.current.destroy();
    typed.current = new Typed(el.current, options);
    typed.current.reset();
    // typed.current = new Typed(el.current, options);
  }

  useEffect(() => {
    setTimeout(() => {
      typed.current = new Typed(el.current, options);
    }, 5000);
    return () => {
      typed.current.destroy();
    };
  }, []);

  return (
    <div
      className="effect-container"
      ref={click}
      style={{ overflow: "hidden" }}
      onClick={playAudio}
    >
      <div className="home-div">
        <div className="nav-div">
          {check ? (
            ""
          ) : (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2 }}
              exit={{ x: "-10vw" }}
              onClick={() => history("/Size")}
            >
              <img src={LArrow} alt="Right Arrow" />
              <p className="nav-button">SIZE</p>
            </motion.button>
          )}
        </div>
        <motion.div
          key="Home"
          className="main-div"
          style={{ maxWidth: "70vw" }}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
            style={{ gridRowStart: 2 }}
          >
            <span
              ref={el}
              style={{
                fontWeight: "book",
                color: info.color[index],
                fontSize: "20px",
              }}
            />
            {textShow ? (
              ""
            ) : (
              <span
                style={{
                  fontWeight: "book",
                  color: info.color[index],
                  fontSize: "20px",
                }}
              >
                {info.name[index]}
              </span>
            )}
          </motion.h2>
          <motion.div
            transition={{ duration: 2 }}
            exit={{ opacity: 1, scale: 0 }}
            style={{ gridRowStart: 3 }}
          >
            <img
              height={200}
              width={index === 6 ? 277 : 200}
              src={info.image[index]}
              alt="Sun"
            />
          </motion.div>
          <motion.button
            className="home-next"
            style={{ gridRowStart: 4 }}
            onClick={nextIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 4 } }}
            transition={{ duration: 2 }}
          >
            NEXT &bull; {info.next[index]}
          </motion.button>
        </motion.div>
        <div className="nav-div">
          {check ? (
            ""
          ) : (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2 }}
              exit={{ x: "10vw" }}
              onClick={() => history("/Distance")}
            >
              <img src={RArrow} alt="Right Arrow" />
              <p className="nav-button">DISTANCE</p>
            </motion.button>
          )}
        </div>
      </div>
      <motion.button
        className="back-button"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.4, transition: { delay: 4 } }}
        transition={{ duration: 2 }}
        exit={{ opacity: 0 }}
        onClick={backClick}
      >
        Back To Title
      </motion.button>
      <motion.button
        className="reset-button"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.4, transition: { delay: 4 } }}
        transition={{ duration: 2 }}
        exit={{ opacity: 0 }}
        onClick={resetType}
      >
        {textShow ? "▶▶ Skip" : "▶ Play"}
      </motion.button>
    </div>
  );
}

export default Home;
