import React, { useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { ClickAnimation } from "./Components/ClickAnimation";
import Planet from "./Components/Planet";
import Circle from "./Components/Circle";
import { PlanetImages } from "./Components/PlanetImages";
import cross from "./Img/cross.svg";

function Distance({ history, playAudio }) {
  // const { ref, inView } = useInView();
  const containerVariants = {
    hidden: {
      x: "100vw",
    },
    visible: {
      // opacity: 1,
      x: 0,
      transition: { delay: 2, duration: 2 },
    },
    exit: {
      opacity: 0,
      x: "100vw",
      transition: { ease: "easeInOut", duration: 2 },
    },
  };
  const [popCheck, setPopCheck] = useState(false);
  const [index, setIndex] = useState(0);
  const animation = useAnimation();

  const info = {
    name: [
      "SUN",
      "MERCURY",
      "VENUS",
      "EARTH",
      "MARS",
      "JUPITER",
      "SATURN",
      "URANUS",
      "NEPTUNE",
    ],
    text: [
      [
        "Star Type: Yellow Dwarf",
        "Radius: 695,700km",
        "Temperature: 5,973°C to 15,000,000°C",
        "99.86% of the mass of the entire solar system",
      ],
      [
        "Planet Weight: 0.33 e24 kg",
        "Temperature: 167 °C",
        "88 days in a year",
        "No Moon",
      ],
      [
        "Planet Weight: 4.87 e24 kg",
        "Temperature: 224.7 °C",
        "224.7 days in a year",
        "No Moon",
      ],
      [
        "Planet Weight: 5.97 e24 kg",
        "Temperature: 15 °C",
        "365.2 days in a year",
        "One Moon",
      ],
      [
        "Planet Weight: 0.64 e24 kg",
        "Temperature: -65 °C",
        "687 days in a year",
        "Two Moons",
      ],
      [
        "Planet Weight: 1898 e24 kg",
        "Temperature: -110 °C",
        "4,331 days in a year",
        "79 Moons",
      ],
      [
        "Planet Weight: 568 e24 kg",
        "Temperature: -140 °C",
        "10,747 days in a year",
        "82 Moons",
      ],
      [
        "Planet Weight: 86.8 e24 kg",
        "Temperature: -195 °C",
        "30,589 days in a year",
        "27 Moons",
      ],
      [
        "Planet Weight: 102 e24 kg",
        "Temperature: -200 °C",
        "59,800 days in a year",
        "14 Moons",
      ],
    ],
    image: PlanetImages,
  };

  const click = useRef();
  ClickAnimation(click, { duration: 1000 });

  function backClick() {
    history("/Home");
  }

  function renderCircles() {
    var circles = [];
    for (var i = 0; i < 60; i++) {
      circles.push(<Circle />);
    }
    circles.push(<Circle height="20vh" />);
    return circles;
  }

  function openPopUp(num) {
    setIndex(num);
    setPopCheck(true);
    setTimeout(() => {
      animation.start({
        opacity: 1,
        transition: { duration: 2 },
      });
    }, 500);
  }

  function closePopUp() {
    animation.start({
      opacity: 0,
      transition: { duration: 2 },
    });
    setTimeout(() => {
      setPopCheck(false);
    }, 2000);
  }

  return (
    <div className="effect-container" ref={click} onClick={playAudio}>
      <motion.div
        style={{ overflowX: "hidden" }}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <h1 className="dist-title">
          The Planets are
          <br />
          f&ensp;a&ensp;r &emsp; a&emsp;p&emsp;a&emsp;r&emsp;t
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <motion.div
            className="distance-div"
            initial={{
              opacity: 0,
              y: "100vh",
            }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 3, delay: 3 },
            }}
            transition={{ duration: 2 }}
          >
            <div style={{ top: 0, position: "relative" }}>
              <Planet
                name="Sun"
                color="#ffe757"
                distance={0}
                popUp={() => openPopUp(0)}
              />
            </div>

            <div style={{ top: "32vh", position: "relative" }}>
              <Planet
                name="Mercury"
                color="white"
                distance={57900000}
                popUp={() => openPopUp(1)}
              />
            </div>

            <div style={{ top: "60vh", position: "relative" }}>
              <Planet
                name="Venus"
                color="white"
                distance={108200000}
                popUp={() => openPopUp(2)}
              />
            </div>

            <div style={{ top: "80vh", position: "relative" }}>
              <Planet
                name="Earth"
                color="#77d1f6"
                distance={149600000}
                popUp={() => openPopUp(3)}
              />
            </div>

            <div style={{ top: "124vh", position: "relative" }}>
              <Planet
                name="Mars"
                color="white"
                distance={227900000}
                popUp={() => openPopUp(4)}
              />
            </div>

            <div style={{ top: "416vh", position: "relative" }}>
              <Planet
                name="Jupiter"
                color="white"
                distance={778600000}
                popUp={() => openPopUp(5)}
              />
            </div>

            <div style={{ top: "764vh", position: "relative" }}>
              <Planet
                name="Saturn"
                color="white"
                distance={1433500000}
                popUp={() => openPopUp(6)}
              />
            </div>

            <div style={{ top: "1524vh", position: "relative" }}>
              <Planet
                name="Uranus"
                color="white"
                distance={2872500000}
                popUp={() => openPopUp(7)}
              />
            </div>

            <div style={{ top: "2384vh", position: "relative" }}>
              <Planet
                name="Neptune"
                color="white"
                distance={4495100000}
                popUp={() => openPopUp(8)}
              />
            </div>
          </motion.div>
          <div className="dist-line">{renderCircles()}</div>
        </div>
      </motion.div>
      {popCheck ? (
        <motion.div
          className="blur-bg"
          // initial={{ opacity: 0 }}
          animate={animation}
          // transition={{ duration: 2 }}
          // exit={animation}
        >
          <motion.div
            className="pop-up"
            initial={{ x: "100vw" }}
            animate={{ x: 0 }}
            transition={{ duration: 2, delay: 1 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "90%",
                paddingLeft: "2rem",
              }}
            >
              <img src={info.image[index]} width="100%" height="50%" />
              <h1>{info.name[index]}</h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // paddingLeft: "2rem",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <p>{info.text[index][0]}</p>
                <p>{info.text[index][1]}</p>
                <p>{info.text[index][2]}</p>
                <p>{info.text[index][3]}</p>
              </div>
            </div>
            <button className="close-button" onClick={closePopUp}>
              <img src={cross} width="90%" />
            </button>
          </motion.div>
        </motion.div>
      ) : (
        ""
      )}
      <motion.button
        className="back-button"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.4, transition: { delay: 4 } }}
        transition={{ duration: 2 }}
        exit={{ opacity: 0 }}
        onClick={backClick}
        style={{ position: "fixed" }}
      >
        Back To Home
      </motion.button>
    </div>
  );
}

export default Distance;
