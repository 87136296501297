import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

function PlanetSize({ planet, scaling, threshold }) {
  const { ref, inView } = useInView({ threshold: threshold });
  const animate = useAnimation();

  useEffect(() => {
    if (inView) {
      animate.start({
        opacity: 1,
        // x: 0,
        // animate={{ opacity: 1, x: 0 }}
        transition: { duration: 1 },
      });
    } else {
      animate.start({
        opacity: 0,
        // x: "-10vw",
        transition: { duration: 2 },
      });
    }
  }, [inView]);

  return (
    <motion.img
      className="size-planets"
      src={planet}
      style={{ height: 100 * scaling, width: 100 * scaling }}
      alt={planet}
      animate={animate}
      ref={ref}
    />
  );
}

export default PlanetSize;
