import React, { useEffect, useRef, useState } from "react";
import { ClickAnimation } from "./Components/ClickAnimation";
import { motion } from "framer-motion";
import Sun from "./Img/Star.svg";
import Mercury from "./Img/Mercury.svg";
import Venus from "./Img/Venus.svg";
import Earth from "./Img/Earth.svg";
import Mars from "./Img/Mars.svg";
import Jupiter from "./Img/Jupiter.svg";
import Saturn from "./Img/Saturn.svg";
import Uranus from "./Img/Uranus.svg";
import Neptune from "./Img/Neptune.svg";
import PlanetSize from "./Components/PlanetSize";

function Size({ history, playAudio }) {
  const [size, setSize] = useState(4.94);
  const click = useRef();
  ClickAnimation(click, { duration: 1000 });

  const planetScale = [0.38, 0.95, 1, 0.53, 11.2, 9.45, 4, 3.88];
  const containerVariants = {
    hidden: {
      x: "-100vw",
    },
    visible: {
      // opacity: 1,
      x: 0,
      transition: { delay: 2, duration: 2 },
    },
    exit: {
      opacity: 0,
      // x: "-100vw",
      transition: { ease: "easeInOut", duration: 2 },
    },
  };

  function backClick() {
    history("/Home");
  }

  const scrolling = (e) => {
    var divSize = document.getElementsByClassName("effect-container")[0];
    const newSize =
      Math.pow(1 - divSize.scrollTop / divSize.clientHeight / 22, 3) * 4.94;
    // console.log(newSize);
    // console.log("ScrollTop: " + divSize.scrollTop);
    // console.log("Scroll Height: " + (divSize.scrollHeight));
    // console.log("Height: " + divSize.clientHeight);
    if (newSize <= 0) {
      setSize(0);
    } else {
      setSize(newSize);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", scrolling);

    return () => {
      window.removeEventListener("scroll", scrolling);
    };
  }, []);

  return (
    <div
      className="effect-container"
      ref={click}
      style={{ minHeight: "100vh" }}
      onScroll={scrolling}
      onClick={playAudio}
    >
      <div
        style={{
          position: "fixed",
          top: "5rem",
          transform: `scale(${size + 0.06})`,
          transformOrigin: "top",
          width: "5000vw",
          marginLeft: "-2450vw",
        }}
      >
        <motion.div
          variants={containerVariants}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 4 } }}
          exit="exit"
        >
          <PlanetSize planet={Sun} scaling={109} threshold={0.3} />
        </motion.div>
      </div>
      <motion.div
        className="container-div"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        style={{
          minHeight: "2200vh",
          // maxHeight: "1000vh"
        }}
      >
        <div className="dist-title size-title">
          <h1 style={{ textAlign: "right" }}>
            The Sun is <br />
            pretty{" "}
          </h1>
          <span style={{ fontSize: "100px", verticalAlign: "middle" }}>
            big
          </span>
        </div>
        <PlanetBlock size={size} planetScale={planetScale} />
      </motion.div>
      <motion.button
        className="back-button"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.4, transition: { delay: 4 } }}
        transition={{ duration: 2 }}
        exit={{ opacity: 0 }}
        onClick={backClick}
        style={{ position: "fixed" }}
      >
        Back To Home
      </motion.button>
    </div>
  );
}

function PlanetBlock({ size, planetScale }) {
  return (
    <div
      className="size-div"
      style={{
        transform: `scale(${size + 0.06})`,
        transformOrigin: "top",
        // backgroundColor: "red",
      }}
    >
      <PlanetSize
        name={"Mercury"}
        planet={Mercury}
        scaling={planetScale[0]}
        threshold={0.7}
      />
      <PlanetSize planet={Venus} scaling={planetScale[1]} threshold={0.7} />
      <PlanetSize planet={Earth} scaling={planetScale[2]} threshold={0.7} />
      <PlanetSize planet={Mars} scaling={planetScale[3]} threshold={0.7} />
      <PlanetSize planet={Jupiter} scaling={planetScale[4]} threshold={0.7} />
      <PlanetSize planet={Saturn} scaling={planetScale[5]} threshold={0.7} />
      <PlanetSize planet={Uranus} scaling={planetScale[6]} threshold={0.7} />
      <PlanetSize planet={Neptune} scaling={planetScale[7]} threshold={0.7} />
    </div>
  );
}

export default Size;
