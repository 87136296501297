import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

function Planet({ name, color, distance, popUp }) {
  const { ref, inView } = useInView({ threshold: 0.3 });
  const animationName = useAnimation();
  const animationIcon = useAnimation();
  const animationVal = useAnimation();

  useEffect(() => {
    if (inView) {
      animationName.start(
        // initial={{ opacity: 0, x: "-10vw" }}
        {
          opacity: 1,
          x: 0,
          // animate={{ opacity: 1, x: 0 }}
          transition: { duration: 1},
        }
      );
      animationIcon.start({
        opacity: 1,
        // x: 0,
        // animate={{ opacity: 1, x: 0 }}
        transition: { duration: 1 },
      });
      animationVal.start({
        opacity: 0.5,
        x: 0,
        // animate={{ opacity: 1, x: 0 }}
        transition: { duration: 1 },
      });
    } else {
      animationName.start({
        opacity: 0,
        x: "-10vw",
        transition: { duration: 2 },
      });
      animationIcon.start({
        opacity: 0,
        // x: "-10vw",
        transition: { duration: 2 },
      });
      animationVal.start({
        opacity: 0,
        x: "10vw",
        transition: { duration: 2 },
      });
    }
  }, [inView]);

  return (
    <div className="dist-planet" ref={ref}>
      <motion.h2
        className="dist-name"
        animate={animationName}
        style={{ color: color }}
      >
        {name}
      </motion.h2>
      <button className="dist-pop-up" onClick={popUp}>
        <motion.svg
          className="dist-icon"
          height={50}
          width={50}
          // initial={{ opacity: 0 }}
          animate={animationIcon}
          // transition={{ delay: 2, duration: 2 }}
          // style={{ top: "1rem" }}
        >
          <circle
            cx="25"
            cy="25"
            r="20"
            fill={color}
            stroke={color}
            strokeWidth="4"
          />
        </motion.svg>
      </button>
      <motion.p
        animate={animationVal}
        className="dist-val"
        style={{ color: color, textAlign: "right" }}
      >
        {(distance || 0).toLocaleString()} km
      </motion.p>
    </div>
  );
}

export default Planet;
