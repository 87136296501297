// import logo from "./logo.svg";
import { BrowserRouter as Router } from "react-router-dom";
import "./css/App.css";
import "./css/Custom.css";
import Particles from "./Components/Particles.js";
import RoutesAnimation from "./Components/RoutesAnimation";

function App() {
  return (
    <div className="App">
      <Router>
        <Particles />
        <RoutesAnimation />
      </Router>
    </div>
  );
}

export default App;
