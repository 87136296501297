import { useEffect } from "react";

export const ClickAnimation = (element, config) => {
  const { size = 100, color = "#fff", duration = 800 } = config;
  
  useEffect(() => {
    const applyProperties = () => {
      element.current.classList.add("effect-container");
    };

    const applyStyle = (e) => {
      let { offsetX, offsetY } = e;
      offsetX = e.clientX;
      offsetY = e.clientY;
      // console.log(offsetX + ", " + offsetY);
      const { style } = element.current;
      const sizeOffset = 50;
      style.setProperty("--effect-duration", `${duration}ms`);
      style.setProperty("--effect-top", `${offsetY - sizeOffset}px`);
      style.setProperty("--effect-left", `${offsetX - sizeOffset}px`);
      style.setProperty("--effect-height", `${size}px`);
      style.setProperty("--effect-width", `${size}px`);
      style.setProperty("--effect-color", color);
    };

    const onClick = (e) => {
      element.current.classList.remove("active");
      applyStyle(e);
      element.current.classList.add("active");
    };

    applyProperties();

    element.current.addEventListener("click", onClick);
    const cleanRef = element.current;

    return () => {
      cleanRef.removeEventListener("click", onClick);
    };
  }, [color, duration, element, size]);
};
